import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Info } from 'lucide-react'; // Using lucide-react icons
import './Disclaimer.css';

const Disclaimer = () => {
	const [isExpanded, setIsExpanded] = useState(true); // Set to true by default

	const toggleDisclaimer = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div className='disclaimer-section'>
			<button
				className={`disclaimer-toggle ${isExpanded ? 'expanded' : ''}`}
				onClick={toggleDisclaimer}
			>
				<Info className='disclaimer-icon' />
				<span className='disclaimer-title'>Disclaimer</span>
				{isExpanded ? (
					<ChevronUp className='disclaimer-chevron' />
				) : (
					<ChevronDown className='disclaimer-chevron' />
				)}
			</button>
			<div
				className={`disclaimer-content ${
					isExpanded ? 'expanded' : 'collapsed'
				}`}
			>
				<p>
					<strong>Please note:</strong> The Rondah AI demo showcases a
					simplified version of Rondah's full capabilities, including
					appointment scheduling, rescheduling, cancellation, and confirmation,
					as well as answering general clinic information.
				</p>
				<p>
					This demo uses an emulated practice management system (PMS) with real
					availability, ensuring that no fictitious patient or scheduling data
					is generated. Like our production agents, this demo agent invokes
					server tools in real-time during conversations to perform workflows
					against the PMS such as searching for patients, finding availability,
					etc.
				</p>
				<p>
					Please be aware that some core workflows have been streamlined to
					accommodate the demo and web environment. Additionally, the demo
					session is limited to a maximum of five minutes to provide an optimal
					experience and fair access to all users.
				</p>
			</div>
		</div>
	);
};

export default Disclaimer;
