import _ from 'lodash';
import {
	createContext,
	useContext,
	useEffect,
	useState,
	useCallback,
} from 'react';
import moment from 'moment-timezone';
import { useAuth } from './useAuth';
import { useSquadConfig } from './useSquadConfig';
import { assistantId, vapi } from '../config/vapi';
import { generateAssistantConfig } from '../assistants';

const VapiContext = createContext();

export function VapiProvider({ children }) {
	const vapiVal = useProvideVapi();
	return (
		<VapiContext.Provider value={vapiVal}>{children}</VapiContext.Provider>
	);
}

export const useVapi = () => {
	return useContext(VapiContext);
};

export const CALL_STATUS = {
	INACTIVE: 'inactive',
	ACTIVE: 'active',
	LOADING: 'loading',
};

function useProvideVapi() {
	const { user } = useAuth();
	const {
		squadConfig,
		loading: squadConfigLoading,
		error: squadConfigError,
	} = useSquadConfig();
	const [isSpeechActive, setIsSpeechActive] = useState(false);
	const [callStatus, setCallStatus] = useState(CALL_STATUS.INACTIVE);
	const [messages, setMessages] = useState([]);
	const [activeTranscript, setActiveTranscript] = useState(null);
	const [audioLevel, setAudioLevel] = useState(0);

	useEffect(() => {
		const onSpeechStart = () => setIsSpeechActive(true);
		const onSpeechEnd = () => {
			// console.log('Speech has ended');
			setIsSpeechActive(false);
		};

		const onCallStartHandler = () => {
			// console.log('Call has started');
			setCallStatus(CALL_STATUS.ACTIVE);

			// try {
			// 	if (!(user && user.email && user.name && user.phone)) {
			// 		console.warn(
			// 			'Missing required user info, cannot log user details at call end.'
			// 		);
			// 		return;
			// 	}

			// 	const { email, name, phone } = user;
			// 	vapi.send({
			// 		type: 'add-message',
			// 		message: {
			// 			role: 'system',
			// 			content: `User details log - name: ${name}, email: ${email}, phone: ${phone}`,
			// 		},
			// 	});
			// 	// console.log('Successfully logged user details at call start')
			// } catch (err) {
			// 	console.error('Failed to log user details at call start:', err);
			// }
		};

		const onCallEnd = () => {
			// console.log('Call has stopped');
			setCallStatus(CALL_STATUS.INACTIVE);
		};

		const onVolumeLevel = (volume) => {
			setAudioLevel(volume);
		};

		const onMessageUpdate = (message) => {
			// console.log('message', message);
			if (
				message.type === 'TRANSCRIPT' &&
				message.transcriptType === 'PARTIAL'
			) {
				setActiveTranscript(message);
			} else {
				setMessages((prev) => [...prev, message]);
				setActiveTranscript(null);
			}
		};

		const onError = (e) => {
			setCallStatus(CALL_STATUS.INACTIVE);
			console.error(e);
		};

		vapi.on('speech-start', onSpeechStart);
		vapi.on('speech-end', onSpeechEnd);
		vapi.on('call-start', onCallStartHandler);
		vapi.on('call-end', onCallEnd);
		vapi.on('volume-level', onVolumeLevel);
		vapi.on('message', onMessageUpdate);
		vapi.on('error', onError);

		return () => {
			vapi.off('speech-start', onSpeechStart);
			vapi.off('speech-end', onSpeechEnd);
			vapi.off('call-start', onCallStartHandler);
			vapi.off('call-end', onCallEnd);
			vapi.off('volume-level', onVolumeLevel);
			vapi.off('message', onMessageUpdate);
			vapi.off('error', onError);
		};
	}, [user]);

	const start = useCallback(() => {
		if (squadConfigLoading) {
			console.warn('Agent config is still loading.');
			return;
		}

		if (squadConfigError) {
			console.error('Error loading agent config.');
			return;
		} else if (!squadConfig) {
			console.error('Agent config is missing.');
			return;
		}

		setCallStatus(CALL_STATUS.LOADING);

		const callSquadConfig = _.cloneDeep(squadConfig);
		try {
			const now = moment().tz('America/New_York').format();
			callSquadConfig.members.forEach((member) => {
				_.set(member, 'assistantOverrides.variableValues.now', now);
			});
		} catch (e) {
			console.warn(
				"Failed to apply timezone adjustment to 'now' variable. Defaulting to UTC."
			);
		}

		vapi
			.start(null, null, callSquadConfig)
			.then((res) => {
				console.log('Call started.');
			})
			.catch((err) => {
				console.error('Failed to start call.');
			});

		// setCallStatus(CALL_STATUS.LOADING);
		// const response = vapi.start(assistantId);
		// // const assistantConfig = generateAssistantConfig();
		// // const response = vapi.start(assistantConfig);

		// response.then((res) => {
		// 	// console.log('call', res);
		// });
	}, [squadConfig, squadConfigLoading, squadConfigError]);

	const stop = () => {
		setCallStatus(CALL_STATUS.LOADING);
		vapi.stop();
	};

	const toggleCall = () => {
		if (callStatus === CALL_STATUS.ACTIVE) {
			stop();
		} else {
			start();
		}
	};

	return {
		isSpeechActive,
		callStatus,
		audioLevel,
		activeTranscript,
		messages,
		start,
		stop,
		toggleCall,
	};
}
