import _ from 'lodash';
import { useEffect, useState } from 'react';
import { createContext, useContext } from 'react';
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { db } from '../config/firebase';
import { useAuth } from './useAuth';

const SquadConfigContext = createContext();

export function SquadConfigProvider({ children }) {
	const squadConfig = useProvideSquadConfig();
	return (
		<SquadConfigContext.Provider value={squadConfig}>
			{children}
		</SquadConfigContext.Provider>
	);
}

export const useSquadConfig = () => {
	return useContext(SquadConfigContext);
};

function useProvideSquadConfig() {
	const { user } = useAuth();
	const [demoConfigRef, setDemoConfigRef] = useState(null);
	const [squadConfigRef, setSquadConfigRef] = useState(null);
	const [squadConfigVersion, setSquadConfigVersion] = useState(null);

	const [demoConfig, demoConfigLoading, demoConfigError] =
		useDocumentData(demoConfigRef);

	const [squadConfig, loading, error] = useDocumentData(squadConfigRef);

	useEffect(() => {
		if (!user || !user.uid) {
			return;
		}

		setDemoConfigRef(
			doc(db, 'remote-configs', 'demo-v2').withConverter(demoConfigConverter)
		);
	}, [user]);

	useEffect(() => {
		if (!demoConfig || !demoConfig.squadConfigVersion) {
			return;
		}

		setSquadConfigVersion(demoConfig.squadConfigVersion);
	}, [demoConfig, demoConfigLoading, demoConfigError]);

	useEffect(() => {
		if (!squadConfigVersion) {
			return;
		}

		setSquadConfigRef(
			doc(db, 'vapi-squad-configs', squadConfigVersion).withConverter(
				squadConfigConverter
			)
		);
	}, [squadConfigVersion]);

	return {
		squadConfig,
		loading: loading || demoConfigLoading,
		error,
	};
}

const demoConfigConverter = {
	fromFirestore: (snapshot, options) => {
		const demoConfig = snapshot.data(options);
		if (_.isEmpty(demoConfig)) {
			console.warn('Demo remote config is missing or invalid.');
		}
		return demoConfig;
	},
};

const squadConfigConverter = {
	fromFirestore: (snapshot, options) => {
		const data = snapshot.data(options);
		const squadConfig = _.get(data, 'squad', {});
		if (_.isEmpty(squadConfig)) {
			console.warn('Demo agent config is missing or invalid.');
		}
		return squadConfig;
	},
};
